import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ContactoInicio from '../../components/inicio/contacto';
import './beneficios.scss';
import { Helmet } from 'react-helmet';
import { HOST } from '../../data/config';
import { ShapeSquare } from '../../components/UI/shapeSquare';
export const Beneficios = props => {
	const [listBenefits, setListBenefits] = useState([]);

	const fetching = async props => {
		const response = await fetch(HOST + '/wp-json/custom-api/v1/benefits');
		const data = await response.json();
        setListBenefits(data);
		console.log(data);
	};
	useEffect(() => {
		fetching();
	}, []);
	return (
		<div className='benefits'>
			<Helmet>
				<title>Casaideal Inmobiliaria | Beneficios y Promociones</title>
				<meta name='description' content='Más de 20 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
				<meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
			</Helmet>
			<section>
				<div className='SliderDepartamentos'>
					<div className='background d-flex'>
						<picture>
							<source className='background ' media='(min-width: 740px)' srcset={require('../../Assets/images/beneficios/background-beneficios.png')}></source>
							<img className='background' src={require('../../Assets/images/beneficios/background-beneficios-movil.png')}></img>
						</picture>
					</div>
					<span className='title-background'>BENEFICIOS CASAIDEAL</span>
				</div>
			</section>
			<section className='cards'>
				<div className='content-cards'>
					{
                        !listBenefits.length && [1,2,3].map(() => {
							return (
								<div >
									<div className='card'>
										<div className='content-img'>
                                            <ShapeSquare></ShapeSquare>
										</div>
										<div className='content-text'>
											<ShapeSquare className='h-1rem w-6rem'></ShapeSquare>
											<div className='mask icon-right'></div>
										</div>
									</div>
								</div>
							);
						})}

					{!!listBenefits.length &&
						listBenefits.map((item) => {
							return (
								<Link to={'/beneficios/' + item.name}>
									<div className='card'>
										<div className='content-img'>
											<img src={item?.imagen_miniatura?.url}></img>
										</div>
										<div className='content-text'>
											<span>{item?.name}</span>
											<div className='mask icon-right'></div>
										</div>
									</div>
								</Link>
							);
						})}
				</div>
			</section>
		</div>
	);
};
