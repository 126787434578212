import React, { Component } from 'react'
import { ubicacion } from '../../ubicaciones'
import $ from 'jquery'
import sr from '../scrollreveal/scrollreveal'
import TimeProjects from '../timeProjects/timeProjects'


// const linea = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.71 5316.56"><defs><style>.cls-1,.cls-3{fill:none;stroke-miterlimit:10;stroke-width:2px;}.cls-1{stroke:#eaeaea;}.cls-2{fill:#e41426;}.cls-3{stroke:#fff;}</style></defs><title>niÃ±oRecurso 212BOTONES</title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><line class="cls-1" x1="35.36" y1="69.59" x2="35.36" y2="1541"/><circle class="cls-2" cx="35.36" cy="35.36" r="35.36"/><circle class="cls-2" cx="35.36" cy="285.56" r="9.24"/><circle class="cls-2" cx="35.36" cy="704.04" r="9.24"/><polyline class="cls-3" points="43.24 31.24 35.35 39.12 27.47 31.24"/><circle class="cls-2" cx="35.36" cy="1122.52" r="9.24"/><circle class="cls-2" cx="35.36" cy="1541" r="9.24"/><line class="cls-1" x1="35.36" y1="1541" x2="35.36" y2="2796.44"/><circle class="cls-2" cx="35.36" cy="1541" r="9.24"/><circle class="cls-2" cx="35.36" cy="1959.48" r="9.24"/><circle class="cls-2" cx="35.36" cy="2377.96" r="9.24"/><circle class="cls-2" cx="35.36" cy="2796.44" r="9.24"/><line class="cls-1" x1="35.36" y1="2796.44" x2="35.36" y2="4051.88"/><circle class="cls-2" cx="35.36" cy="2796.44" r="9.24"/><circle class="cls-2" cx="35.36" cy="3214.92" r="9.24"/><circle class="cls-2" cx="35.36" cy="3633.4" r="9.24"/><circle class="cls-2" cx="35.36" cy="4051.88" r="9.24"/><line class="cls-1" x1="35.36" y1="4051.88" x2="35.36" y2="5307.31"/><circle class="cls-2" cx="35.36" cy="4051.88" r="9.24"/><circle class="cls-2" cx="35.36" cy="4470.35" r="9.24"/><circle class="cls-2" cx="35.36" cy="4888.83" r="9.24"/><circle class="cls-2" cx="35.36" cy="5307.31" r="9.24"/></g></g></svg>'
const linea = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.71 5316.56"><defs><style>.cls-1,.cls-3{fill:none;stroke-miterlimit:10;stroke-width:2px;}.cls-1{stroke:#eaeaea;}.cls-2{fill:#e41426;}.cls-3{stroke:#fff;}</style></defs><title>niÃ±oRecurso 212BOTONES</title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><line class="cls-1" x1="35.36" y1="69.59" x2="35.36" y2="1541"></line><circle class="cls-2" cx="35.36" cy="35.36" r="35.36" id="circulo" ></circle><circle class="cls-2" cx="35.36" cy="285.56" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="704.04" r="9.24"></circle><polyline class="cls-3" points="43.24 31.24 35.35 39.12 27.47 31.24"></polyline><circle class="cls-2" cx="35.36" cy="1122.52" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="1541" r="9.24"></circle><line class="cls-1" x1="35.36" y1="1541" x2="35.36" y2="2796.44"></line><circle class="cls-2" cx="35.36" cy="1541" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="1959.48" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="2377.96" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="2796.44" r="9.24"></circle><line class="cls-1" x1="35.36" y1="2796.44" x2="35.36" y2="4051.88"></line><circle class="cls-2" cx="35.36" cy="2796.44" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="3214.92" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="3633.4" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="4051.88" r="9.24"></circle><line class="cls-1" x1="35.36" y1="4051.88" x2="35.36" y2="5307.31"></line><circle class="cls-2" cx="35.36" cy="4051.88" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="4470.35" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="4888.83" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="5307.31" r="9.24"></circle></g></g></svg>'
const circulo = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.35 46.35"><defs><style>.modelo-circulo{fill:#e41426;}.modelo-circulo2{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}</style></defs><title>niÃ±oRecurso 312BOTONES</title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><circle class="modelo-circulo" cx="23.18" cy="23.18" r="23.18"/><polyline class="modelo-circulo2" points="30.99 19.23 23.11 27.12 15.22 19.23"/></g></g></svg>'
export default class Nosotros extends Component {
    constructor(props){
        super(props)
        this.moveTo = this.moveTo.bind(this)
        this.offset = this.offset.bind(this)
    }
    componentDidMount(){
        let time = setInterval(() => {
            if(window.location.search.includes("proyectos-entregados")){
                this.moveTo("#nosotros","#proyectos-entregados")
            }
            clearInterval(time)
        }, 200);
        $(document).ready(function(){
            sr.reveal(".imagen", {origin:"bottom",duration:1200,distance:"120px",delay:200,reset:false,easing:"ease-out"});
            sr.reveal(".time-paragraph", {origin:"bottom",duration:1200,distance:"120px",delay:200,reset:false,easing:"ease-out"});
            sr.reveal(".paragraph", {origin:"bottom",duration:1200,distance:"120px",delay:200,reset:false,easing:"ease-out"});
        });
      
    }
    moveTo(elementFrom,elementTo){
        let navHeight = document.querySelector("#nav").clientHeight
        window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight+50))
    }
    offset(e){
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
        }
    }
    render() {
        return (
            <div class="nosotros" id="nosotros">
                <section class="section-info">
                    <div className="content-info">
                        <img alt="nosotros casaideal" className='' src={require('../../Assets/nosotros/nosotros-casa-ideal.jpg')}></img>
                    </div>
                    <div className="content-paragraph">
                        <h2 className="title">Quiénes somos</h2>
                        <p className="paragraph">Somos una organización liderada por empresarios peruanos que cuenta con 20 años de experiencia en el mercado, dedicada al negocio inmobiliario a través del diseño, construcción y comercialización de viviendas multifamiliares en Lima.</p>
                        <p className="paragraph">Contamos con un equipo de profesionales altamente capacitados y aplicamos las últimas técnicas y productos que se encuentran disponibles en la industria de la construcción.</p>
                    </div>
                    <div className="content-paragraph">
                        <h2 className="title">Visión</h2>
                        <p className="paragraph">Ser una empresa admirada por sus clientes, empleados y proveedores, responsable socialmente y líder en el mercado al que se dirige.</p>
                    </div>
                    <div className="content-paragraph">
                        <h2 className="title">Misión</h2>
                        <p className="paragraph">Contribuir con el desarrollo de las ciudades en las que operamos, entregando productos inmobiliarios de calidad e innovadores, y procurando la satisfacción total de nuestros clientes a través de un servicio integral y personalizado.</p>
                    </div>
                </section>
                <section className="timing">
                    <h2 className="title" id="proyectos-entregados">Certificaciones y Asociaciones que nos respaldan</h2>
                    {/*  */}
                    <div className="brands">
                        <div className="brand">
                            <div className="content-item">
                                <span className="title ">MIEMBRO DE:</span>
                                <div className="two">
                                    <img className="item bestplace" src={require("../../Assets/images/iconos/asei.png")}></img>
                                    <img alt="capeco" className="item bestplace" src={require("../../Assets/images/iconos/capeco.png")}></img>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
